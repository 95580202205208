<template>
  <div class="root">
    <div class="content">
      <h2 class="align-center">科企岛隐私政策</h2>
      <p style="text-align:right;" class="bold">更新日期：2021年10月28日</p>
      <p style="text-align:right;" class="bold">生效日期：2021年11月1日</p>
      <div class="content-text">
        <p class="suojin">科企岛（以下或称“我们”）非常注重保护用户（“您”）的个人信息及隐私，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。我们希望通过本隐私政策向您清晰地介绍在使用我们的产品/服务时，我们如何处理您的个人信息，以及我们为您提供的访问、更正、删除和保护这些信息的方式。</p>
        <p><b>本政策将帮助您了解以下内容：</b></p>
        <p><b>1、我们如何收集和使用您的个人信息</b></p>
        <p><b>2、我们如何使用 Cookie和同类技术</b></p>
        <p><b>3、我们如何共享、转让、公开披露您的个人信息</b></p>
        <p><b>4、我们如何保存和保护您的个人信息</b></p>
        <p><b>5、您如何管理您的个人信息</b></p>
        <p><b>6、通知和修订</b></p>
        <p><b>7、如何联系我们</b></p>
        <p><b>8、附录</b></p>
        <p><b>【特别提示】请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本《隐私政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择。一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照本隐私政策处理您的相关信息。</b></p>
        <p><b>如对本隐私政策有任何疑问，您可以通过本隐私政策“如何联系我们”中提供的方式与我们联系） 。</b></p>
        <p>科企岛的产品/服务是由科企岛（上海）网络技术有限公司（注册地址：上海市松江区龙腾路1021号）及其关联方提供者通过下述途径向您提供的产品/服务：包括但不限于科企岛网站（www.keqidoa.com）、科企岛App客户端以及相关微信开放平台账号或小程序等。针对某些特定的产品/服务，我们还将制定单独的隐私政策，向您说明这些产品/服务的特殊政策。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。</p>
        <p>本政策所称的科企岛及其关联方是指）科企岛）上海网络技术有限公司及现有和/或未来设立的相关关联公司的单称或合称。</p>
        <p><b>请您注意，本政策不适用于您通过我们的产品/服务而接入的其他第三方产品/服务(“其他第三方”，包括您的交易相对方、任何第三方网站以及第三方服务提供者等)，具体规定请参照该第三方的隐私政策或类似声明。</b></p>

        <h4>一、我们如何收集和使用您的个人信息</h4>
        <p>在您使用我们的产品/服务时，您需要/可以选择授权我们收集和使用个人信息的场景包括：</p>
        <p>1、为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形，如您拒绝提供前述必要信息，您将无法正常使用我们的的产品/服务；</p>
        <p>2、为了向您提供我们产品/服务的拓展功能，您可以选择授权我们收集、使用信息的情形，如您拒绝提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响您正常使用我们产品/服务的基本功能。</p>
        <p>（一）您需要授权我们收集和使用个人信息的场景</p>
        <p>我们会遵循正当、合法、必要的原则，出于本政策所述的下列目的收集和使用您的个人信息。</p>
        <p><b>1、帮助您成为我们的用户</b></p>
        <p>为遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，在您注册成为科企岛用户时，您需要至少提供手机号码以创建科企岛账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）；如果您仅需使用浏览、搜索等功能，您无需注册成为我们的用户以及提供上述信息。</p>
        <p><b>在提供账号信息的过程中，如果您愿意额外补充如下个人信息，将有助于我们向您提供更为个性化的服务：包括您的性别、生日、常居地、家乡、喜好、家庭信息等。如果您不提供该等信息，不会影响您享受科企岛服务的基本功能。</b></p>
        <p><b>如您选择授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息（如头像、昵称、地区、性别等信息）与您的科企岛账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。</b></p>
        <p><b>2、向您提供基于地理位置的信息展示</b></p>
        <p>为了向您提供产学研合作信息的展示和搜索服务，提升我们的服务表现、效率和功能，经您授权我们会收集您在使用我们服务过程中产生的相关信息，包括：</p>
        <p><b>（1）位置信息。当您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会收集和使用您的位置信息以便为您推荐周边的技术专家</b></p>（例如您不需要手动输入所在地理位置就可获得相关服务，估算专家与您之间的实际距离方便您进行合作决策，为您推荐附近的服务品类和优惠信息等）。我们会使用有关技术获取您的位置信息（准确度会有所不同），这些技术包括 IP 地址、GPS 以及能够提供相关信息的WLAN（如Wi-Fi） 接入点、蓝牙和基站等。<b>您使用服务中提供的信息中可能包含相应地理位置信息，例如您在账号资料中设置的地区信息或您上传的图片、视频中的地理标记信息。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。</b>
        <p><b>（2）日志信息。</b></p>当您使用我们提供的产品/服务时，我们会收集您的浏览、搜索、点击、收藏、交易、售后、关注、分享、发布等信息并作为有关网络日志进行保存，其中包括您的IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。
        <p><b>（3、为您提供预订、下单、订单管理功能</b></p>
        您在科企岛上使用产学研合作会议等服务时，您需要根据所选择的产品功能类型提供<b>联系人信息（姓名、性别、电话号码等）、地址信息</b>、服务偏好等。
        您在科企岛上创建线上实验室等业务时，您可能需要根据有关法律规定和相关方要求（票务、酒店、旅行社及其代理方等）提供您的<b>实名身份信息（包括但不限于您的身份证、军官证、护照、驾驶证、学生证等载明您身份的证件照片、复印件、号码等）、联系人信息（姓名、性别、电话号码等）</b>、电子邮箱地址（用于确认订单信息等）、<b>个人照片（用于初诊等）、同行人信息、紧急联络人信息</b>等。
        <p>为向您展示您账号的订单信息及便于您对订单信息的管理，我们会收集您在使用我们服务过程中产生的订单信息。</p>
        <p><b>您在科企岛上生成的订单中，将可能包含您的身份信息、联络信息、行程信息、住宿信息、支付信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供</b>，我们也将会在展示订单信息时，在保证信息可用性的基础上尽量去标识化处理。</p>
        <p><b>4、为您提供收藏、关注、分享、推荐功能</b></p>
        <p>为了便于您管理自己的偏好，寻找具有共同爱好的用户或社区，获得更轻松的访问体验，与好友及第三方进行分享等，您可以收藏/关注/加入您感兴趣的圈子/用户/部落，也可以通过功能组件向第三方进行信息分享，我们会收集您在使用上述功能中产生的日志信息，用于在个人主页中向您展示、实现在线管理、发起共享以及向您推荐具有共同爱好的用户等。</p>
        <p><b>5、为您提供路演服务</b></p>
        <p><b>您在开通路演服务时，根据法律法规的要求，可能需要通过“人脸识别”的方式进行实名认证，以验证您的身份。在实名认证的过程中，我们和提供认证服务的第三方认证机构需要您提供真实姓名、身份证号码及面部特征值信息。这些信息是个人敏感信息，您可以拒绝提供，且不会影响科企岛其他功能的正常使用。这些信息仅供实名认证及法律法规所规定的用途，未经您明示授权，不会用作其他目的。</b></p>
        <p><b>您在使用路演服务时，我们将收集您路演的音视频信息，并展示您的昵称、头像及路演信息。</b></p>
        <p><b>您在使用路演服务时，我们会请求您授权相机、麦克风权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用科企岛其他功能。</b></p>
        <p><b>6、为您提供客服和售后服务</b></p>
        <p>当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息以便核验身份；当您需要我们提供与您订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息（如实验室信息，企业信息等）时，您可能还需要提供上述信息外的其他信息以便完成修改。</p>
        <p>为了保证服务体验、处理服务纠纷，您在订单履行过程中拨打或者接听开启号码保护（隐私号）的电话时，您与专家，投资人间的通话信息可能会被录音。有关上述信息的收集和使用规则如下，或参见订单页面中的提示内容。</p>
        <p>（1）经授权，当您与专家，投资人间出现服务纠纷时，平台可调取并使用录音信息作为解决纠纷的参考依据。 </p>
        <p>（2）平台将切实保障通话双方的隐私安全，录音将上传至科企岛服务器保存，各方无法自行下载、调取或播放录音；除以下情形外，平台不会将录音提供给任何人（包括通话主、被叫方）：a.有权机关依法定程序调取；b.被录音的一方持司法机关出具的法律文件依法调取。 </p>
        <p>（3）录音在保存7天后将自动永久删除，如遇合同纠纷、技术纠纷等尚未处理完毕的纠纷等，平台将适当延长录音保存期限。</p>
        <p><b>7、为您提供安全保障</b></p>
        <p>为了保障您的账号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，在您使用我们的产品/服务过程中，经您授权我们会获取您的设备信息，<b>包括您使用的设备属性、连接和状态信息，例如设备型号、设备标识符（如IMEI/androidID/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息等）、设备MAC地址、软件列表、电信运营商等软硬件特征信息。</b></p>
       <p> 为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以及我们的关联方、合作伙伴取得您授权或者依法共享的信息，根据您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击等安全风险以及违反我们或关联方协议、政策或规则等行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接(“URL”)。</p>
        <p><b>8、我们可能间接收集的个人信息</b></p>
        <p>为完成身份核验，保障账号安全，确认交易状态及为您提供售后与争议解决等目的，经您授权后我们可能会从第三方获取您的个人信息（例如通过您选择的交易对象和交易直接涉及的第三方信息系统、支付机构等收集与交易进度相关的您的交易、支付、出票、酒店预订、外卖预订等信息，以便于我们处理您的订单并保证服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为）。我们会依据法律法规的要求以及与第三方的约定，经您授权后向科企岛的关联方、合作伙伴获得您的有关信息，并对其信息来源的合法性进行确认后使用您的这些信息。</p>
        <p><b>（二）您可以选择授权我们收集和使用个人信息的场景</b></p>
        <p>为向您提供个性化的服务，您可以选择使用我们提供的拓展功能，我们会在符合法律规定并根据您具体授权的情况下收集并使用如下信息。这类信息将在您选择的具体功能和业务场景中进行收集，如果您不提供这些信息，不会影响您使用科企岛的基本功能。</p>
        <p><b>1、基于相机授权的拓展功能</b></p>
        <p><b>您可以选择开启系统的相机权限，通过使用拍照、录视频，扫码等功能授权科企岛访问您的相机，以便于您通过拍摄照片或录制视频等方式发布内容</b>，如果您需要录制并发布有声视频时，您还需开启麦克风权限。我们会收集您上传发布的上述信息，此项功能您可以在系统权限中关闭，一旦关闭您将可能无法实现扫码功能，或者无法通过拍摄图片、视频等方式进行点评或互动，<b>但不会影响您享受科企岛服务的基本功能。</b></p>
        <p><b> 2、基于相册授权的拓展功能</b></p>
        <p><b>您可以选择开启系统的相册权限，通过主动上传图片、视频等方式授权我们访问您的相册，以便于您通过上传照片或上传视频等方式发布内容。</b>我们会收集您选择上传发布的上述信息，此项功能您可以在系统权限中关闭，一旦关闭您将可能无法通过上传图片、视频等方式进行更换头像、发布带图点评等，<b>但不会影响您享受科企岛服务的基本功能。</b></p>
        <p><b> 3、基于麦克风授权的拓展功能</b></p>
        <p><b>您可以选择开启系统的麦克风权限，使用语音技术来实现语音搜索、语音通话等语音交互功能。</b>我们会收集您在使用智能语音技术过程中录入的语音信息用于机器识别、在线交互并满足您的搜索或下单等需求（例如您在使用科企岛外卖的“语音通话”功能或者科企岛的“按住说话”功能时，我们会根据您的需求进行周边专家技术的搜索为您推荐）。此项功能您可以在系统权限中关闭，一旦关闭您将可能无法实现在线语音交互功能，<b>但不会影响您享受科企岛服务的基本功能。</b></p>
        <p><b>4、基于通讯录授权的拓展功能</b></p>
        <p><b>您可以授权（例如通过独立的《信息授权使用协议》等）使用我们的服务创建交流与分享功能，并允许科企岛获取您手机或其他第三方社交平台中的通讯录信息和公开信息（头像、昵称等），从而使您及您通讯录中同样授权开通此项功能的联系人，能够在科企岛上分享有关内容（头像、昵称、评价信息等）以实现互动功能。您还可以授权我们读取您的通讯录信息用于为他人预定服务而无需再手动输入，例如为他人进行手机话费充值。</b>此项功能您可以在系统权限中关闭，一旦关闭您将可能无法实现基于通讯录的互动功能，<b>但不会影响您享受科企岛服务的基本功能。</b></p>
       <p><b> 5、基于好友推荐的拓展功能</b></p>
        我们也可能基于您授权的通讯录信息、设备信息、好友关系、日志信息为您推荐好友或将您推荐给其他用户，帮助您拓展社交圈。此项功能您可以在隐私设置中进行关闭或解除好友关系，也可以自主管理允许他人查看的范围（例如关注的人、粉丝、分享时展示的头像昵称等）。一旦关闭您将可能无法实现与您的好友在科企岛进行分享交流，<b>但不会影响您享受科企岛服务的基本功能。</b>
        <p><b>6、基于日历授权的拓展功能</b></p>
        <b>您可以开启系统的日历权限，使用添加日历等功能记录科企岛相关服务的日程，我们会收集上述信息用于向您展示或定时提醒。</b>此项功能您可以在系统权限中关闭，一旦关闭您将可能无法享受日历记录或提醒功能，<b>但不会影响您享受科企岛服务的基本功能。</b>
        <p><b>7、基于运动与健身授权的拓展功能 </b></p>
        <b>您可以开启系统的运动与健身权限，使用步数统计等运动健康功能，我们会收集上述信息用于向您进行步数统计等。</b>此项功能您可以在系统权限中关闭，一旦关闭您将可能无法享受步数统计等功能，<b>但不会影响您享受科企岛服务的基本功能。</b>
        <p><b>（三）其他</b></p>
        <p><b>您可以通过科企岛为其他人预定各类产品/服务，并提供该实际服务接收人的有关信息，此前请您确保已取得相关人的授权。</b></p>
        如果我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。
        <p><b>（四）征得同意的例外</b></p>
        请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
        <p><b>1、为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</b></p>
        <p><b>2、为履行法定职责或者法定义务所必需；</b></p>
        <p><b>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</b></p>
        <p><b>4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</b></p>
        <p><b>5、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</b></p>
        <p><b>6、法律法规规定的其他情形。</b></p>
        <p><b>请注意，单独或与其他信息相结合无法识别您的身份或者与您直接建立联系的信息，不属于个人信息。如果我们将单独无法与任何特定个人建立联系的信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。</b></p>
        <p><b>（五）个人信息的使用规则</b></p>
        为满足您的个性化需求，维护、改进我们的产品或服务质量，我们会在符合法律规定并根据您具体授权的情况下使用如下信息：
        <p><b>1、我们可能会收集您的订单信息、浏览及搜索信息、行为偏好（如您向我们提供的膳食习惯、就餐环境要求、旅行计划、常用乘客/酒店/租车信息、航班舱位、行李和票务选择等信息）、位置信息等，以及将您在科企岛上使用某项服务中提供的信息与来自其他服务中的信息结合起来，进行综合统计、分析以形成用户画像，用来向您推荐或展示您可能感兴趣的产品/服务信息，或通过系统向您展示个性化的第三方推广信息。包括在App页面向您推送消息通知，为您提供智能推荐，通过短信、APP 、电子邮件等给您发送推送消息、推广信息或展示商业广告，通过电话进行回访、向您提供信息咨询等服务或邀请您参与服务、产品或功能有关的客户调研。例如将您在使用我们某项服务时收集的信息，用于在另一项服务中向您展示个性化的内容或广告。</b></p>
        <p>2、我们可能会收集您在参与产品/服务调查时主动向我们提供的信息，以及您与我们的关联方、合作伙伴之间互动时提供的相关信息，以便于您追踪订单情况、发起用户投诉以及我们优化客户服务的质量与流程。</p>
        <p>3、我们可能将业务中收集的个人信息用于统计分析和改进运营，将已经去标识化无法识别您身份且不能复原的信息用于建立数据库并进行商业化利用。例如通过您所在的位置、偏好等进行统计分析，从而改进我们的产品、服务或营销计划；又如为改进我们系统而进行的技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。
        </p>
        <p><b>（六）设备权限调用</b></p>
        <p>为确保相关业务功能的正常实现，我们需要根据具体的使用场景调用对应的必要权限，并在调用前向您弹窗询问，具体的权限调用说明请见下表：</p>
        <p><b>科企岛权限列表:</b></p>

        <div>
          <div class="list">
            <div style="width:90%;" class="columns">
              <div style="width:15%;text-align:left;border-right:2px solid black;border-top:2px solid black;">
                <b style="padding:10px;font-size:16px;">设备权限</b>
                <div class="list-item" v-for="(item,index) of items1" :key="index">
                  <div v-if="index === 6" style="height:45px;padding-top: 15px;">{{item}}</div>
                  <div v-else>{{item}}</div>
                </div>
              </div>
              <div style="width:50%;text-align:left;border-right:2px solid black;border-top:2px solid black;">
                <b style="padding:10px;font-size:16px;">使用目的</b>
                <div class="list-item" v-for="(item,index) of items2" :key="index">
                  <div v-if="index === 6" style="height:45px;padding-top: 15px;">{{item}}</div>
                  <div v-else>{{item}}</div>
                </div>
              </div>
              <div style="width:22%;text-align:left;border-top:2px solid black;border-right:2px solid black;">
                <b style="padding:10px;font-size:16px;">是否询问</b>
                <div class="list-item" v-for="(item,index) of items3" :key="index">
                  <div v-if="index === 6" style="height:55px;padding-top: 5px;">{{item}}</div>
                  <div v-else>{{item}}</div>
                </div>
              </div>
              <div style="width:13%;text-align:left;border-top:2px solid black;">
                <b style="padding:10px;font-size:16px;">是否可关闭</b>
                <div class="list-item" v-for="(item,index) of items4" :key="index">
                  <div v-if="index === 6" style="height:45px;padding-top: 15px;text-align:center;">{{item}}</div>
                  <div v-else style="text-align:center;">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>您可以在设备的设置中选择关闭部分或者全部权限，这可能导致对应的业务功能无法实现或者无法达到预期效果。</p>
        <h4>二、我们如何使用Cookie和同类技术</h4>
        <p><b>（一）Cookie的使用</b></p>
        为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为 Cookie的小数据文件。Cookie会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息(例如一键登录等)的流程;为您提供安全购物的偏好设置;帮助您优化对广告的选择与互动;保护您的数据安全等。我们不会将 Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机或移动设备上保存的所有 Cookie，您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie;另外，您也可以清除软件内保存的所有Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。
        <p><b>（二）网络Beacon和同类技术的使用</b></p>
        除Cookie外，我们网页上常会包含一些电子图象(称为"单像素"GIF文件或"网络Beacon")等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些Cookie。我们使用网络Beacon的方式有:
        1、我们通过在我们网站上使用网络Beacon，计算用户访问数量，并通过访问Cookie辨认注册用户。
        2、我们通过得到的Cookie信息，可以在我们网站提供个性化服务。


        <h4>三、我们如何共享、转让、公开披露您的个人信息</h4>
        <p>在我们向您提供产品/服务时，我们会采取严格的安全措施，由我们所完成的产品/服务中我们不会与科企岛以外的任何公司、组织和个人共享您的个人信息。但请您知悉，我们提供的产品/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息，以保障和优化我们为您提供的产品/服务，此类情况包括：</p>
        <p>1、提供平台服务。我们可能会作为电子商务平台向您提供产品/服务，如您在我们的平台上使用其他平台或商家提供的产品/服务，我们可能会与相应产品/服务的提供方共享您的必要个人信息，以便于您能够正常使用相应的浏览、下单、交易、支付等功能。</p>
        <p>2、提供统一管理服务。如您使用我们的账号登录和使用我们关联公司和其他第三方所提供的产品/服务，我们可能会共享您的个人信息。我们只会共享必要的个人信息以便向您提供一致化的服务和统一管理，例如在我们的产品/服务内查看订单并进行管理，如果我们共享您的个人敏感信息或者关联方改变个人信息的使用目的，将再次征求您的授权同意。</p>
        <p>3、提供必要的合作服务。仅为实现本政策中声明的目的，我们的某些服务将由业务合作伙伴提供。为保障为您提供的服务顺利完成，我们可能会将您的个人信息共享给我们的合作伙伴，包括配送业务、技术服务、支付服务、金融业务等的供应商、服务合作商、第三方商家等，其中可能包括您的联络信息、订单信息、支付信息、地址信息等。</p>
        <p>例如在您使用我们的外卖服务时，我们必须与配送服务合作商共享您的订单和配送信息，以保证订单的安全准确送达；又如我们需要将您的订单号和订单金额与第三方支付机构共享以实现确认您的支付指令并完成支付；将您的订单信息、必要交易信息与第三方商家共享，以便其向您提供商品、服务及售后等。其中您的联络信息和地址信息属于个人敏感信息，我们将努力通过采取“号码保护”、单据上的“地址隐藏”等合适的举措尽全力保护您的个人信息。</p>
        <p>4、保障安全和优化服务。为了保障您在使用我们产品/服务时的安全，以及优化我们的服务，我们可能会与我们的相关合作伙伴共享您的必要信息。</p>
        <p>安全保障服务。为了保障您的账号安全，避免您遭受欺诈、网络病毒、网络攻击等风险，我们可能会用与我们安全保障的合作伙伴共享与您有关的必要设备信息、日志信息，以及您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，预防安全事件的发生。</p>
        <p>统计、优化服务。经您授权，我们可能会与提供广告投放推广和统计分析的合作伙伴共享信息，用于相关合作伙伴可能会对上述信息进行汇聚分析，提供广告曝光监测或服务决策，为您提供更好的服务。但我们仅会向这些合作伙伴提供广告推广的覆盖面、有效性以及统计类等信息，而不会共享可以识别您身份的个人信息，否则我们会对这些信息进行去标识化处理以避免对您身份的识别。</p>

        <p>5、基于法定情形提供。根据法律法规的规定、诉讼争议解决需要、您与我们签署的相关协议(包括在线签署的电子协议及平台规则)或法律文件，或行政、司法等有权机关依法提出要求时，我们可能会共享您的个人信息。</p>

        <p>6、除了上述情况外，我们如果对其他任何公司、组织和个人共享您的个人信息，会再次征求您的明确同意或授权。</p>
        <p>我们会通过应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方SDK会有所不同，一般包括第三方账号登录类、分享类、第三方支付类、地图导航类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。</p>
        <p>为保障科企岛App相关功能的实现与应用的稳定运行，我们可能会接入由第三方提供的软件开发包（SDK）以实现相关目的。不同版本的第三方SDK会有所不同，一般包括一键登录类、第三方账号登录类、推送通知消息类、第三方支付类、地图导航类、厂商推送类、分享类、统计类等。我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保障数据安全。您可以通过以下链接查看第三方的数据使用和保护规则。</p>
        <p><b>科企岛与第三方共享信息列表</b></p>
        <p>在我们向您提供产品/服务时，我们会采取严格的安全措施，同时由于部分产品/服务是美团无法单独完成的，因此我们可能会与其他合作商等第三方共享或委托处理您的部分个人信息，以保障产品/服务的顺利完成。</p>
        <p> <b>功能类型：</b>一键登录</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供一键登录功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>中国移动&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://dev.10086.cn/">https://dev.10086.cn/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://wap.cmpassport.com/resources/html/contract.html">https://wap.cmpassport.com/resources/html/contract.html</a></p>
        <p>中国联通&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://onlinebusiness.10010.com">https://onlinebusiness.10010.com</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://wap.cmpassport.com/resources/html/contract.html">https://wap.cmpassport.com/resources/html/contract.html</a></p>
        <p>中国电信&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://id.189.cn/">https://id.189.cn/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=">https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=</a></p>
        <p> <b>功能类型：</b>第三方账号登录</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供第三方账号登录功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>微信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.weixin.qq.com/">https://open.weixin.qq.com/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p>QQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://connect.qq.com/index.html">https://connect.qq.com/index.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722">https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722</a></p>


        <p> <b>功能类型：</b>推送通知消息</p>
        <p> <b>使用目的：</b>根据用户机型，为用户提供通知信息推送功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>华为&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://developer.huawei.com/consumer/cn/service/hms/pushservice.html">https://developer.huawei.com/consumer/cn/service/hms/pushservice.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://consumer.huawei.com/cn/privacy/privacy-policy/">https://consumer.huawei.com/cn/privacy/privacy-policy/</a></p>
        <p>小米&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://dev.mi.com/console/appservice/push.html">https://dev.mi.com/console/appservice/push.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p>VIVO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://dev.vivo.com.cn/documentCenter/doc/366#w1-08185430">https://dev.vivo.com.cn/documentCenter/doc/366#w1-08185430</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.vivo.com.cn/about-vivo/privacy-policy">https://www.vivo.com.cn/about-vivo/privacy-policy</a></p>
        <p>OPPO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.oppomobile.com/wiki/doc#id=10704">https://open.oppomobile.com/wiki/doc#id=10704</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://privacy.oppo.com/cn/policy/">https://privacy.oppo.com/cn/policy/</a></p>
        <p>魅族&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.flyme.cn/open-web/views/push.html">https://open.flyme.cn/open-web/views/push.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.meizu.com/legal.html">https://www.meizu.com/legal.html</a></p>

        <p> <b>功能类型：</b>第三方支付</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供第三方支付功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>银联支付&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.unionpay.com/tjweb/index">https://open.unionpay.com/tjweb/index</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="http://yunshanfu.unionpay.com/privacy/2">http://yunshanfu.unionpay.com/privacy/2</a></p>
        <p>微信支付&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://pay.weixin.qq.com/static/applyment_guide/applyment_index.shtml">https://pay.weixin.qq.com/static/applyment_guide/applyment_index.shtml</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml">https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a></p>
        <p>QQ支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://wiki.open.qq.com/wiki/%E6%94%AF%E4%BB%98%E6%8E%A5%E5%85%A5">https://wiki.open.qq.com/wiki/%E6%94%AF%E4%BB%98%E6%8E%A5%E5%85%A5</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml">https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a></p>
        <p>支付宝&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://docs.open.alipay.com/54">https://docs.open.alipay.com/54</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a></p>


        <p> <b>功能类型：</b>地图导航</p>
        <p> <b>使用目的：</b>为用户提供地图导航功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>腾讯地图&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://lbs.qq.com/">https://lbs.qq.com/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="http://yunshanfu.unionpay.com/privacy/2">http://yunshanfu.unionpay.com/privacy/2</a></p>
        <p>高德地图&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://lbs.amap.com/">https://lbs.amap.com/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://lbs.amap.com/pages/privacy/">https://lbs.amap.com/pages/privacy/</a></p>


        <p> <b>功能类型：</b>信息分享</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供内容分享至第三方平台功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p style="word-break:break-all;">微信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.weixin.qq.com/cgi-bin/showdocument?action=dir_list&amp;t=resource/res_list&amp;verify=1&amp;id=1417751808&amp;token=&amp;lang=zh_CN" >https://open.weixin.qq.com/cgi-bin/showdocument?action=dir_list&amp;t=resource/res_list&amp;verify=1&amp;id=1417751808&amp;token=&amp;lang=zh_CN</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p>QQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://connect.qq.com/index.html">https://connect.qq.com/index.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722">https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722</a></p>
        <p>请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</p>
        <p><b>（二）转让</b></p>
        随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、重组、资产转让或类似的交易，如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。
        <p>除了上述情况，我们不会未经您的明确同意将您的个人信息进行转让。</p>
        <p><b>（三）公开披露</b></p>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才可能公开披露您的个人信息：
        <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
        <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。</p>
        <p><b>请注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。</b></p>
        <p><b>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</b></p>
        以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
        <p>1、与国家安全、国防安全有关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>5、其他维护公共利益的情形，例如您的信用评价信息需要被公开/共享；</p>
        <p>6、您自行向社会公众公开的个人信息；</p>
        <p>7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。但是您明确拒绝或者处理该信息侵害您重大利益的除外。</p>
        <p>根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。</p>

        <h4>四、我们如何保存和保护您的个人信息</h4>
        <p class="bold">（一）个人信息的保存</p>
        <p class="bold">1、保存期限：我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律法规有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；保证我们为您提供服务的安全和质量；您是否同意更长的留存期间；是否存在关于保留期限的其他特别约定或法律法规规定。
        在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。在您主动注销账号时，我们将根据法律法规的要求进行数据处理。</p>
        <p><b>2、保存地域：我们在中华人民共和国境内收集和产生的个人信息，将存储在中国境内，但以下情形除外：法律法规有明确规定的；单独征得您的授权同意；您主动发起的跨境预定、下单、交易等个人行为（如购买国际机票、预订国际酒店等）。</b></p>
        <p>在上述情形中，我们会根据法律法规要求履行相应流程和义务，并要求数据接收方按照不低于本隐私政策以及其他相关的安全保密措施来处理个人信息。</p>
        <p><b>3、终止运营：</b>如果发生终止运营等情形，我们将会至少提前30天通知您，并在终止运营后对您的个人信息根据法律法规的要求进行处理。</p>
        <p class="bold">（二）个人信息的保护措施</p>
        <p>1、数据安全措施</p>
        <p>为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。科企岛通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如在您的浏览器与“服务”之间交换数据(如信用卡信息)时采用SSL加密保护技术;同时对网站本身提供https安全浏览方式;使用加密技术确保数据的保密性;使用受信赖的保护机制防止数据遭到恶意攻击;部署访问控制机制，确保只有授权人员才可访问个人信息;以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
        <p>2、安全认证</p>
        <p>我们已先后通过了公安部信息安全等级保护三级认证、ISO27001信息安全认证、第三方支付行业（支付卡行业PCI DSS)数据安全标准国际认证等认证资质，并与监管机构、第三方测评机构建立了良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。</p>
        <p>3、请您知悉并理解，<b>互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。</b></p>
        <p>4、安全事件</p>
        <p>在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>


        <h4>五、您如何管理您的个人信息</h4>
        <p>您对您的个人信息享有以下权利:</p>
        <p><b>（一）您有权访问、更正、删除您的个人信息，法律法规规定的例外情况除外。</b>您可以通过以下方式管理您的信息:</p>
        <p><b>账号信息</b>——您可以访问、更正您账号中的个人资料及账号信息（身份认证信息除外）、支付信息、账号绑定信息等，也可以更改您的密码、添加安全信息或进行第三方账号和银行卡的解绑等，您可以通过访问网页及App在设置中执行此类操作。</p>
        <p><b>地址信息</b>——您可以通过访问科企岛网页/App页面上的“设置”—“收货地址”或“我的地址”中随时添加、更改、删除<b>您的收货地址信息（包括收货人姓名、性别、收货地址、电话号码等）</b>。</p>
        <p><b>搜索浏览信息</b>您可以在科企岛网页/App中访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。订单信息——您可以通过访问“我的订单”页面查看您的所有已经完成、待付款、待售后、待评价的订单。您可以选择删除已完成的订单信息，但这样可能导致我们无法根据您的购买信息而准确提供相应的售后服务。</p>
        <p><b>评论信息</b>——您可以通过访问科企岛网页/App页面上的“评价”、“评价中心”访问、更正、清除您的评论内容。</p>
        <p><b>请您知悉，根据法律法规的要求及不同信息的技术条件需要，我们可能无法立即从系统中完全删除您所希望删除的相应信息，在此之前，我们将根据法规要求对相应信息仅进行存储并采取必要的安全保护措施方面的处理。</b></p>
        <p><b>（二）改变您授权同意的范围或撤回授权</b></p>
        <p>您可以通过在科企岛App/大众点评App中删除信息、更改隐私设置或者在系统中关闭设备权限功能等方式改变同意范围或撤回您的授权。</p>
        <p>您可以通过我的-设置-隐私管理-设备权限管理来操作撤回您的授权。</p>
        <p>请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权已经开展的个人信息处理。</p>

        <p><b>（三）注销账号</b></p>
        <p>您有权注销您的科企岛账号，您可以通过访问我们App页面上的“设置”——“注销账号”中进行在线操作。<b>您注销成功后</b>，您注销成功后，除法律法规及相应的国家标准另有规定外，我们将删除您的个人信息或作匿名化处理。</p>
        <p>如果您无法通过上述方式访问、更正或删除您的个人信息以及注销账号，或者如果您认为科企岛存在违反法律的规定收集、使用您个人信息的情形，您可以通过我们的客服或通过本隐私政策提供的其他方式与我们取得联系。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
        <p><b>（四）自主管理控制营销信息及定向推送</b></p>
        <p>消息推送——如您不希望收到App页面上的消息通知，可以在首次安装App时拒绝接收消息通知，或者在移动端操作系统中的“通知”中心关掉对应的通知功能。</p>
        <p>个性化推荐——如您不希望使用我们的智能推荐服务，可以自主选择按照距离、好评、价格等不针对您个人特征的方式进行排序，也可以在推荐信息页面中通过“不感兴趣”、“不想看”等功能进行精准反馈和实时关闭；还可以在设置-隐私管理中进行自主管理控制。</p>
        <p>个性化广告——如您不希望接收个性化的广告，您可以在“我的”-“设置”-“隐私管理”-“接收个性化推荐”中自行控制开关，当您关闭后，您看到的广告相关性会降低，但广告不会消失。</p>
        <p>推广/回访——如您不希望接收我们的推广短信或电话回访，可通过信息中相应的退订功能进行退订，或者在电话回访中明确表示拒绝。</p>
        <h4>六、通知和修订</h4>
        <p>为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整，对我们的基本情况、您的个人信息的处理目的方式种类和保存期限、您管理个人信息的方式、我们的联系方式发生变更的，我们会进行及时更新。未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。</p>
        <p>我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。</p>
        <h4>七、如何联系我们</h4>
        <p>您可以通过以下方式与我们联系，我们将在15天内答复您的请求；</p>
        <p>1、您可以通过科企岛网站（如www.keqidao.com）/App上提供的在线联系方式/客服系统与我们联系；</p>
        <p>2、您可以联系科企岛的客服电话进行反馈（科企岛网021-57683339）。</p>
        <p>3、我们还设立了专门的个人信息保护团队，您可以联系我们的个人信息保护负责人邮箱 keqidao@keqidao.com ，或者您也可以寄信到如下地址：北上海市松江区龙腾路1021号，邮编：201600。</p>
        <p><b>附录：</b></p>
        <p><b>1、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中可能涉及到的个人信息包括但不限于：个人基本信息（包括姓名、电话号码、性别、住址、生日等）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（包括银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；个人健康生理信息（包括病症、病历、病史等）；联系人信息（包括通讯录信息、好友列表等）；个人上网记录（包括网站浏览记录、点击记录等）；个人设备信息（包括唯一设备识别码等描述个人常用设备基本情况的信息）；个人位置信息（包括行踪轨迹、精准定位信息、住宿信息等）。</b></p>
        <p><b>2、个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中可能涉及到的个人敏感信息包括：个人身份认证信息（包括身份证、军官证、护照、驾驶证、学生证等）；个人财产信息（银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；其他信息（行踪轨迹、通讯录信息、住宿信息、精准定位信息等）；14周岁以下的未成年人信息。</b></p>
        <p>3、科企岛：本政策中所指的“科企岛”是指科企岛旗下所有公司及其附属、关联公司（包括科企岛网已经成立的公司及未来可能成立的公司）。</p>
        <p>4、关联公司：指科企岛（keiqdao）最新公司年报披露的科企岛关联公司。</p>
        <p>5、关联账号：您可以使用相同的手机号码登录科企岛所提供的产品/服务，并授权使用您的科企岛账号信息（如头像、昵称、收货地址），以便于我们基于关联账号向您提供一致的服务以及您进行统一管理。</p>
        <h2 class="align-center">隐私政策附件</h2>
        <p class="suojin">
          我们非常注重保护用户（“您”）的个人信息及隐私，并希望通过本概要向您简洁介绍我们如何收集、使用和保护您的个人信息。<b>本概要为《科企岛隐私政策》的附件，如您希望了解我们详细的隐私政策，请查阅概要后的隐私政策正文。</b></p>
        <h4>一、我们如何收集和使用您的个人信息</h4>
        <p> &nbsp;&nbsp;<b><u>注册信息</u></b></p>
        <p> 在您注册成为科企岛用户时，您需要至少提供手机号码以创建科企岛账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）；如果您仅需使用浏览、搜索等功能，您无需注册成为我们的用户以及提供上述信息。</p>
        <p> &nbsp;&nbsp;<b><u>地理位置</u></b></p>
        <p> <b>使用目的：</b>经您授权，我们会收集您的地理位置信息，以便为您推荐周边的专家实验室，以及估算实验室和您之间的距离以便于您进行合作决策等。</p>
        <p> <b>信息说明：</b>位置信息的来源包括 IP 地址、GPS 以及能够提供相关信息的WLAN（如Wi-Fi） 接入点、蓝牙和基站等传感器信息。您使用服务中提供的信息中可能包含相应地理位置信息，例如您在账号资料中设置的地区信息或您上传的图片、视频中的地理标记信息。</p>
        <p>当您拒绝位置授权后仍然可以手动选择或输入位置信息，不会影响您使用基本服务。请您知悉，在个别场景下（如地图导航等服务）位置是必要的信息，如果您不同意授权位置信息则可能会影响您的正常使用。</p>
        <p> &nbsp;&nbsp;<b><u>相机</u></b></p>
        <p> <b>使用目的：</b>经您授权，我们会收集您的相机信息，以便为您提供扫码、拍照等功能。</p>
        <p> <b>信息说明：</b>我们仅在您使用对应功能时收集相应信息，例如您使用扫码拍照等功能时。不同的系统显示的名称可能有所差异，如相机、摄像头等。</p>
        <p>您可以自行选择开启或关闭相机的授权，一旦关闭您将可能无法使用扫码、拍照等功能，但不会影响您使用浏览、下单等基本服务。</p>
        <p> &nbsp;&nbsp;<b><u>相册</u></b></p>
        <p> <b>使用目的：</b>经您授权，我们会收集您的相册信息，以便为您上传和保存照片、视频等功能。</p>
        <p> <b>信息说明：</b>我们仅在您使用对应功能时收集相应信息，例如您使用上传和保存照片、视频等功能时。不同的系统显示的名称可能有所差异，如相册、存储权限等。</p>
        <p>您可以自行选择开启或关闭相册的授权，一旦关闭您将可能无法通过上传图片、视频等方式来发布评价等功能，但不会影响您使用浏览、下单等基本服务。</p>
        <p> &nbsp;&nbsp;<b><u>麦克风</u></b></p>
        <p> <b>使用目的：</b>经您授权，我们会收集您的录音信息，以便为您提供语音通话、语音聊天等语音交互功能。</p>
        <p> <b>信息说明：</b>我们仅在您使用对应功能时收集相应信息，通过申请麦克风权限以便获得声音，以提供对应的识别后续的交互功能。不同的系统显示的名称可能有所差异，如麦克风、录音权限等。</p>
        <p>您可以自行选择开启或关闭麦克风授权，一旦关闭您将可能无法使用诸如“按住说话”、语音交互等相关功能，但不会影响您使用浏览、文字聊天等基本服务。麦克风权限需要您的额外授权，您也可以在系统权限中自主控制，我们绝不会私自开启您的麦克风权限，更不会非法利用非法手段进行相关信息的收集和使用，请您放心。</p>
        <p> &nbsp;&nbsp;<b><u>设备信息</u></b></p>
        <p> <b>使用目的：</b>在您访问或使用我们服务的过程中，我们会收集您的设备信息，以便获取屏幕分辨率等信息进行界面展示、避免其他设备的异常登录、留存必要的日志信息，从而保障您的账号安全、交易安全和系统运行安全。此外，如您未登录账号，我们使用您的设备信息以便为您推送对应的信息展示。</p>
        <p> <b>信息说明：</b>我们收集的设备状态包括您的设备型号，IMEI/android ID/IDFA/OPEN UDID/GUID/OAID、SIM卡IMSI、ICCID信息等设备标识符、设备MAC地址、移动应用列表、电信运营商等。未经您的授权，我们不会提前获取以上信息。目前多数手机终端厂商支持对设备标识符的更改，以保障您对个人信息的自主控制，具体撤回或更改的方式可参见您所使用的终端设备说明。</p>
        <p> &nbsp;&nbsp;<b><u>您使用过程中主动提供的信息</u></b></p>
        <p> <b>使用目的：</b>在您使用我们提供的产品/服务过程中，我们根据您使用的特定场景可能会收集您提供的个人信息，以实现您所需要使用的功能。</p>
        <p> <b>信息说明：</b>我们会根据具体的场景请求您提供对应的信息并会严格依法保护您的个人信息，例如合作前填写的联系人信息、地址信息，合作评价时需要提供昵称、头像、相关评价图片/视频等发布内容。此外，如果您愿意额外补充您的性别、生日、喜好、常居地等个人资料，将有助于我们为您提供更加个性化的服务，但如果您不提供该等信息，不会影响您使用我们产品/服务的基本功能。</p>
        <p>除法律另有规定外，我们仅会在实现目的所必需的最短时间内留存您的相关个人信息。如您希望详细了解我们所收集的具体各项个人信息及对应的场景，请查阅概要后的隐私政策正文。对于我们收集的个人信息清单明细，您可以查阅以下科企岛App收集个人信息清单</p>
        <div>
          <div class="list">
              <b style="width:70%;border:2px solid black;text-align:center; padding:10px 0; font-size:24px">科企岛APP收集个人信息清单</b>
            <div style="width:70%;" class="columns">
              <div style="width:20%;text-align:center;border-right:2px solid black;">
                <b style="padding:10px;font-size:16px;">场景/业务功能</b>
                <div class="list-item" v-for="(item,index) of list1" :key="index">
                  <div v-if="index === 3" style="height:75px;padding-top:25px;">{{item}}</div>
                  <div v-else-if="index === 4 || index === 6" style="height:40px;padding-top:20px;">{{item}}</div>
                  <div v-else-if="index === 8 || index === 9" style="height:65px;padding-top:35px;">{{item}}</div>
                  <div v-else-if="index === list1.length - 1" style="height: 140px;padding-top: 110px;">{{item}}</div>
                  <div v-else>{{item}}</div>
                </div>
              </div>
              <div style="width:30%;text-align:center;border-right:2px solid black;">
                <b style="padding:10px;font-size:16px;">个人信息种类</b>
                <div class="list-item" v-for="(item,index) of list2" :key="index">
                  <div v-if="index === 3" style="height:65px;padding-top:35px;">{{item}}</div>
                  <div v-else-if="index === 8 || index === 9" style="height:90px;padding-top:10px;">{{item}}</div>
                  <div v-else-if="index === 4" style="height:55px;padding-top: 5px;">{{item}}</div>
                  <div v-else-if="index === 6" style="height:40px;padding-top:20px;">{{item}}</div>
                  <div v-else-if="index === list2.length - 1" style="height: 150px;padding-top: 100px;">{{item}}</div>
                  <div v-else>{{item}}</div>
                </div>
              </div>
              <div style="width:50%;text-align:center;">
                <b style="padding:10px;font-size:16px;">目的</b>
                <div class="list-item" v-for="(item,index) of list3" :key="index">
                  <div v-if="index === 3" style="height:90px;padding-top:10px;">{{item}}</div>
                  <div v-else-if="index === 9" style="height:65px;padding-top:35px;">{{item}}</div>
                  <div v-else-if="index === 8" style="height:75px;padding-top:25px;">{{item}}</div>
                  <div v-else-if="index === 4" style="height:40px;padding-top:20px;">{{item}}</div>
                  <div v-else-if="index === 6" style="height:55px;padding-top: 5px;">{{item}}</div>
                  <div v-else-if="index === list3.length - 1" style="height: 245px;padding-top: 5px;text-align:left;padding-left:20px">
                    <div v-for="(jtem,i) of item" :key="i">
                      {{jtem}}
                    </div>
                    <b><u>上述附加功能仅在特定场景下用户使用相关功能时才会申请询问，用户拒绝不影响用户使用基本功能</u></b>
                  </div>
                  <div v-else>{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <h4>二、我们如何共享您的信息</h4>
        <p>请您知悉，<b>我们为您所提供的产品/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息。</b>目前，除再次获得您明确同意或授权外，此类共享或委托处理涉及的主要情形包括：根据行政、司法等有权机关的要求提供必要信息；为提供账号一致性服务与关联公司共享必要信息；为提供如配送、第三方支付等服务与有关合作商、第三方商家、支付机构等共享有关的必要信息等。</p>
        <p>我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</p>
        <p>对于我们使用的第三方的软件工具开发包（“SDK”），您可以击查看下方科企岛接入第三方SDK目录进行查阅。 </p>
        <p><b>科企岛与第三方共享信息列表</b></p>
        <p>在我们向您提供产品/服务时，我们会采取严格的安全措施，同时由于部分产品/服务是美团无法单独完成的，因此我们可能会与其他合作商等第三方共享或委托处理您的部分个人信息，以保障产品/服务的顺利完成。</p>
        <p> <b>功能类型：</b>一键登录</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供一键登录功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>中国移动&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://dev.10086.cn/">https://dev.10086.cn/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://wap.cmpassport.com/resources/html/contract.html">https://wap.cmpassport.com/resources/html/contract.html</a></p>
        <p>中国联通&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://onlinebusiness.10010.com">https://onlinebusiness.10010.com</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://wap.cmpassport.com/resources/html/contract.html">https://wap.cmpassport.com/resources/html/contract.html</a></p>
        <p>中国电信&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://id.189.cn/">https://id.189.cn/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=">https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=</a></p>
        <p> <b>功能类型：</b>第三方账号登录</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供第三方账号登录功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>微信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.weixin.qq.com/">https://open.weixin.qq.com/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p>QQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://connect.qq.com/index.html">https://connect.qq.com/index.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722">https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722</a></p>


        <p> <b>功能类型：</b>推送通知消息</p>
        <p> <b>使用目的：</b>根据用户机型，为用户提供通知信息推送功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>华为&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://developer.huawei.com/consumer/cn/service/hms/pushservice.html">https://developer.huawei.com/consumer/cn/service/hms/pushservice.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://consumer.huawei.com/cn/privacy/privacy-policy/">https://consumer.huawei.com/cn/privacy/privacy-policy/</a></p>
        <p>小米&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://dev.mi.com/console/appservice/push.html">https://dev.mi.com/console/appservice/push.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p>VIVO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://dev.vivo.com.cn/documentCenter/doc/366#w1-08185430">https://dev.vivo.com.cn/documentCenter/doc/366#w1-08185430</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.vivo.com.cn/about-vivo/privacy-policy">https://www.vivo.com.cn/about-vivo/privacy-policy</a></p>
        <p>OPPO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.oppomobile.com/wiki/doc#id=10704">https://open.oppomobile.com/wiki/doc#id=10704</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://privacy.oppo.com/cn/policy/">https://privacy.oppo.com/cn/policy/</a></p>
        <p>魅族&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.flyme.cn/open-web/views/push.html">https://open.flyme.cn/open-web/views/push.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.meizu.com/legal.html">https://www.meizu.com/legal.html</a></p>

        <p> <b>功能类型：</b>第三方支付</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供第三方支付功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>银联支付&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.unionpay.com/tjweb/index">https://open.unionpay.com/tjweb/index</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="http://yunshanfu.unionpay.com/privacy/2">http://yunshanfu.unionpay.com/privacy/2</a></p>
        <p>微信支付&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://pay.weixin.qq.com/static/applyment_guide/applyment_index.shtml">https://pay.weixin.qq.com/static/applyment_guide/applyment_index.shtml</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml">https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a></p>
        <p>QQ支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://wiki.open.qq.com/wiki/%E6%94%AF%E4%BB%98%E6%8E%A5%E5%85%A5">https://wiki.open.qq.com/wiki/%E6%94%AF%E4%BB%98%E6%8E%A5%E5%85%A5</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml">https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a></p>
        <p>支付宝&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://docs.open.alipay.com/54">https://docs.open.alipay.com/54</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a></p>


        <p> <b>功能类型：</b>地图导航</p>
        <p> <b>使用目的：</b>为用户提供地图导航功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p>腾讯地图&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://lbs.qq.com/">https://lbs.qq.com/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="http://yunshanfu.unionpay.com/privacy/2">http://yunshanfu.unionpay.com/privacy/2</a></p>
        <p>高德地图&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://lbs.amap.com/">https://lbs.amap.com/</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://lbs.amap.com/pages/privacy/">https://lbs.amap.com/pages/privacy/</a></p>


        <p> <b>功能类型：</b>信息分享</p>
        <p> <b>使用目的：</b>根据用户选择，为用户提供内容分享至第三方平台功能</p>
        <p>第三方名称&nbsp;&nbsp;&nbsp;第三方链接</p>
        <p style="word-break:break-all;">微信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://open.weixin.qq.com/cgi-bin/showdocument?action=dir_list&amp;t=resource/res_list&amp;verify=1&amp;id=1417751808&amp;token=&amp;lang=zh_CN" >https://open.weixin.qq.com/cgi-bin/showdocument?action=dir_list&amp;t=resource/res_list&amp;verify=1&amp;id=1417751808&amp;token=&amp;lang=zh_CN</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p>QQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：<a href="https://connect.qq.com/index.html">https://connect.qq.com/index.html</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策链接：<a href="https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722">https://docs.qq.com/doc/DQVFLeGd4Ym1oTm5P?pub=1&dver=2.1.27013722</a></p>
        <p>请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</p>
        <h4>三、您如何管理您的信息</h4>
        <p>您有权管理您的个人信息，包括查询、更正和删除您的账号信息、地址信息、搜索记录、评论信息，改变您授权同意的范围或撤回授权，以及注销您的账号。您可以通过我们的App中提供的功能设置进行上述操作。</p>
        <p>如果您无法通过上述方式进行管理，您可以通过我们以下提供的联系方式联系我们，我们将在15天内回复您的要求。</p>
        <h4>四、您如何联系我们</h4>
        <p>您可以通过以下方式与我们联系，我们将在15天内答复您的请求：</p>
        <p>1、您可以通过科企岛网站或科企岛App上面的反馈与帮助联系；</p>
        <p>2、您可以联系科企岛的客服电话进行反馈（科企岛网：021-57683339）。</p>
        <p>3、我们还设立了专门的个人信息保护团队，您可以联系我们的个人信息保护负责人邮箱 keqidao@keiqdao.com ，或者您也可以寄信到如下地址：上海市松江区龙腾路1021号807隐私部门（收），邮编：201600。</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "privacyagreement",
  created() {
    this.$store.commit('message/SET_ACTIVENAV', '-')
    this.$store.commit('message/SET_BG', false)
  },
  data(){
    return{
      list1:['注册','登录','第三方帐号登录','基于位置的推荐和供给展示','外卖下单'
      ,'实名认证','身份核验','使用过程中','搜索浏览','客服与售后',
      '附加功能'],
      list2:['手机号、验证码、密码','头像、昵称','第三方账号','位置','联系人信息、地址（收货地址）、联系电话、订单交易信息'
      ,'真实姓名、身份证号、手机号','实名认证信息、人脸识别信息','设备信息、日志信息、网络信息','访问及使用过程中产生的点击、浏览、关注、收藏、搜索、查询、分享等产生的信息','用户与客服的联系记录、账号信息、身份核验信息、订单信息等客服解决所必要的信息',
      '相机、相册、麦克风、通讯录、日历、运动与健身'],
      list3:['用于注册创建账号和登录','用于完善网络身份标识','用于使用第三方账号授权登录的情形','根据用户的位置推荐周边的技术专家、投资人等信息，用户可以自行选择开启位置权限，或者在关闭位置权限时也可以手动输入位置','用于技术实现交易完成履约'
      ,'用于进行实名认证','部分情形下进行身份核验可能会涉及人脸识别信息，届时将会依法取得单独授权','用于安全保障','根据用户的搜索、浏览等需求进行相应展示和推荐。用户可以通过个性化推荐开关来自主控制','用于客服与售后服务及争议处理',
      ['相机：扫码、拍照、上传图片','相册：从相册中上传图片和储存图片','麦克风：用于语音录入智能点餐、语音聊天、按住说话','通讯录：用于分享、交流、互动','日历：用户向日历中添加行程和提醒','运动与健身：用于用户参与运动健身及步数统计功能']],
      items1:['位置','IDFA(IOS12及以上)','相机','麦克风','通讯录','照片','日历','后台访问位置','录音','存储','悬浮窗'],
      items2:['用语推荐周边专家进行专家讲座会谈','用户保障用户的账号、信息安全及接收个性化广告','拍照、拍摄视频','语音聊天，语音通话','用语分享、交流、互动','用户写入和读取用户相册信息','用于向用户提供日程管理、项目周期管理等提醒服务','用户用户搜索后推荐周边专家等','语音聊天','用于存储用户拍摄的照片和视频，以及实现应用内升级也页面数据缓存等','用于用户保持消息提醒快捷入口等功能'],
      items3:['首次打开客户端弹窗时询问','首次打开客户端时询问','用户主动拍照、拍摄视频时询问','用户发起语音前询问','用户主动上传通讯录前询问','用户主动上传前询问','用户将项目进度计划列在添加在日历中提醒前询问','首次打开客户端时询问','用户发起语音通话前询问','用户主动上传前询问','用户主动使用时询问'],
      items4:['是','是','是','是','是','是','是','是','是','是','是'],
    }
  }
}
</script>

<style scoped lang='scss'>
.root {
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* WebKit */
  -ms-user-select: none;
  /* IE */
  -khtml-user-select: none;
  /* KHTML */
  -o-user-select: none;
  /* Opera */
  user-select: none;
  /* CSS3属性 */

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
}

.content {
  width: 1080px;
  line-height: 2;
}

.align-center {
  text-align: center;
}

.content-text {
  font-size: 10.5000pt;
}

.bold {
  font-weight: bold;
}

.suojin {
  text-indent: 2em;
}
.list{
  display: flex;
  flex-direction: column;
  align-items: center;
  .columns{
    display: flex;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    justify-content: space-between;
    width: 33%;
    .list-item{
      padding: 10px 0;
      box-sizing: border-box;
      border-top: 2px solid black;
    }
  }
}
</style>
