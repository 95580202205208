<template>
  <div class="container">
    <header class="custom-header">
      <div class="header flex-cc">
        <img src="@/assets/images/activity/blue-coral/header-logo.png" class="logo"/>
        <p @click="scrollToSmooth('section0')">蓝珊瑚简介</p>
        <p @click="scrollToSmooth('section1')">蓝珊瑚服务</p>
        <p @click="scrollToSmooth('section2')">入驻蓝珊瑚</p>
        <p @click="scrollToSmooth('section3')">蓝珊瑚园区</p>
        <p><a href="/">返回科企岛</a></p>
      </div>
    </header>
    <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/10/03/1699010716618025" class="top-img" />
    <div class="section-0" id="section0">
      <div class="content">
        <p class="title">蓝珊瑚BHTI 简介</p>
        <p class="content">蓝珊瑚隶属于科企岛数字科创平台，是专注于科技创业企业和创业团队发展的线上线下一体化的<br/>服务载体。它的主要宗旨是以数字化、数据化服务科技创新创业，促进科技成果转化，优化创新<br/>创业生态环境。</p>
        <p class="content m-30">蓝珊瑚主要面向科技型创业企业和创业团队，提供线上数字科创中心、对接线下物理空间、科创<br/>大数据、共享设施和专业化服务。服务包括但不限于科技支持、产业支持、投资支持，从创业场<br/>地、创业设施、技术服务、咨询服务、投资融资、创业辅导、品牌服务、资源对接等方面立体化<br/>赋能，通过数据集成、人工智能等方式，为入驻企业提供全要素、一站式的孵化加速服务。</p>
        <p class="content">通过提供全要素一站式的孵化加速服务，蓝珊瑚帮助科技创新缩短孵化周期，提高孵化效率，降<br/>低孵化成本，同时提供更便捷的融资渠道和更高效的技术支持。</p>
        <p class="bottom">About us</p>
        <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/09/20/1697807219235738" class="img"/>
      </div>
    </div>
    <div class="section-1" id="section1">
      <div class="content">
        <p class="title">蓝珊瑚服务</p>
        <div class="img-line">
          <div class="img-section">
            <img src="@/assets/images/incubator/img-0.png" class="img" />
            <p class="info">数字科创中心</p>
            <div class="bottom">
              <div class="flex-cc">
                <p class="left">数字科创中心</p><p class="middle">|</p><p class="right">技术咨询—专家通</p>
              </div>
              <div class="flex-cc">
                <p class="left">项目合作——项目通</p><p class="middle">|</p><p class="right">融资服务——创投通</p>
              </div>
            </div>
          </div>
          <div class="img-section">
            <img src="@/assets/images/incubator/img-1.png" class="img" />
            <p class="info">科创管家服务</p>
            <div class="bottom">
              <div class="flex-cc">
                <p class="left-small">专利申请</p>
                <p class="middle">|</p>
                <p>高新申请</p>
                <p class="middle">|</p>
                <p class="right-small">政策申报</p>
              </div>
              <div class="flex-cc">
                <p class="left" style="width: 170px;">创业辅导</p><p class="middle">|</p><p class="right" style="width: 164px;">法财税务</p>
              </div>
            </div>
          </div>
          <div class="img-section">
            <img src="@/assets/images/incubator/img-2.png" class="img" />
            <p class="info">科技融媒体</p>
            <div class="bottom">
              <div class="flex-cc">
                <p class="left">品牌服务</p><p class="middle" style="margin: 0 65px;">|</p><p class="right">推广服务</p>
              </div>
              <div class="flex-cc">
                <p class="left" style="margin-left: -8px;">宣传片</p><p class="middle" style="margin: 0 65px;margin-left: 74px;">|</p><p class="right">科技流量</p>
              </div>
            </div>
          </div>
        </div>
        <div class="img-line">
          <div class="img-section-big">
            <img src="@/assets/images/incubator/img-3.png" class="img" />
            <p class="info">产业载体</p>
            <div class="bottom">
              <p>线上园区<span>|</span>线下园区<span>|</span>产业政策</p>
            </div>
          </div>
          <div class="img-section-big">
            <img src="@/assets/images/incubator/img-4.png" class="img" />
            <p class="info">科技融资</p>
            <div class="bottom">
              <p class="p-l">科技融资<span>|</span>科技金融超市</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-back" id="section2">
      <p class="p-title">入驻蓝珊瑚</p>
      <div class="line-options flex-cc" v-if="!hasType">
        <div class="option" :class="{'active': cur === 0}" @click="changeCur(0)">科技项目</div>
        <div class="option m-80" :class="{'active': cur === 1}" @click="changeCur(1)">资本</div>
        <div class="option" :class="{'active': cur === 2}" @click="changeCur(2)">产业园</div>
      </div>
      <div class="form-container" :style="`${hasType ? 'justify-content: center;' : ''}`">
        <div class="right-form" v-loading="loadingForm">
          <div v-if="cur === 0">
            <div class="title">
              {{ textTitle.title }}
            </div>
            <div class="form-line">
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form1 }}
                </div>
                <input v-model="form.name" :placeholder="'请输入' + textTitle.form1" class="input" maxlength="100">
              </div>
              <div class="form-item form-contact" v-if="!userInfo.id">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form2 }}
                </div>
                <div class="phone-line">
                  <input v-model="form.phone" :placeholder="'请输入' + textTitle.form2" class="input" maxlength="11">
                  <div class="code-line">
                    <input v-model="form.code" :placeholder="'验证码'" class="code" maxlength="6" />
                    <div @click="getPhoneCode()" class="button" :class="{'disable-code': !showCode}">
                      {{ showCode ? '验证码' : count + '秒' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-line">
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>联系人
                </div>
                <input v-model="form.personName" :placeholder="'请输入联系人'" class="input" maxlength="50">
              </div>
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>{{ textTitle.form3 }}
              </div>
              <textarea v-model="form.introduce" :placeholder="'请输入' + textTitle.form3" class="textarea" type="textarea" maxlength="500"  />
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>{{ textTitle.form4 }}
              </div>
              <textarea v-model="form.describe" :placeholder="'请输入' + textTitle.form4" class="textarea" type="textarea" maxlength="500" />
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>项目需求
              </div>
              <textarea v-model="form.detail" :placeholder="'请输入项目需求：'" class="textarea" type="textarea" maxlength="500" />
            </div>
          </div>
          <div v-else-if="cur === 1">
            <div class="title">
              {{ textTitle.title }}
            </div>
            <div class="form-line">
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form1 }}
                </div>
                <input v-model="form.name" :placeholder="'请输入' + textTitle.form1" class="input" maxlength="50">
              </div>
              <div class="form-item form-contact" v-if="!userInfo.id">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form2 }}
                </div>
                <div class="phone-line">
                  <input v-model="form.phone" :placeholder="'请输入' + textTitle.form2" class="input" maxlength="11">
                  <div class="code-line">
                    <input v-model="form.code" :placeholder="'验证码'" class="code" maxlength="6" />
                    <div @click="getPhoneCode()" class="button" :class="{'disable-code': !showCode}">
                      {{ showCode ? '验证码' : count + '秒' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-line">
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form3 }}
                </div>
                <input v-model="form.address" :placeholder="'请输入' + textTitle.form3" class="input" maxlength="50">
              </div>
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form4 }}
                </div>
                <el-select v-model="form.scale" clearable :placeholder="'请选择' + textTitle.form4" class="select-input">
                  <el-option
                    v-for="(item) in scaleSelections"
                    :key="item.id"
                    :label="item.val"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>{{ textTitle.form5 }}
              </div>
              <textarea v-model="form.describe" :placeholder="'请输入' + textTitle.form5" class="textarea" type="textarea" maxlength="500" />
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>项目需求
              </div>
              <textarea v-model="form.detail" :placeholder="'请输入项目需求：'" class="textarea" type="textarea" maxlength="500" />
            </div>
          </div>
          <div v-else-if="cur === 2">
            <div class="title">
              {{ textTitle.title }}
            </div>
            <div class="form-line">
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form1 }}
                </div>
                <input v-model="form.name" :placeholder="'请输入' + textTitle.form1" class="input" maxlength="50">
              </div>
              <div class="form-item form-contact" v-if="!userInfo.id">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form2 }}
                </div>
                <div class="phone-line">
                  <input v-model="form.phone" :placeholder="'请输入' + textTitle.form2" class="input" maxlength="11">
                  <div class="code-line">
                    <input v-model="form.code" :placeholder="'验证码'" class="code" maxlength="6" />
                    <div @click="getPhoneCode()" class="button" :class="{'disable-code': !showCode}">
                      {{ showCode ? '验证码' : count + '秒' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-line">
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form3 }}
                </div>
                <el-cascader ref="cascaderAddr" :props="props"
                  class="select-input"
                  :placeholder="'请选择' + textTitle.form3"
                  @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"
                  v-model="form.addressArr">
                </el-cascader>
              </div>
              <div class="form-item">
                <div class="form-title">
                  <span>*</span>{{ textTitle.form4 }}
                </div>
                <input v-model="form.scale" :placeholder="'请输入' + textTitle.form4" class="input" maxlength="50">
              </div>
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>{{ textTitle.form5 }}
              </div>
              <textarea v-model="form.describe" :placeholder="'请输入' + textTitle.form5" class="textarea" type="textarea" maxlength="500" />
            </div>
            <div class="form-text-area">
              <div class="form-title">
                <span>*</span>项目需求
              </div>
              <textarea v-model="form.detail" :placeholder="'请输入项目需求：'" class="textarea" type="textarea" maxlength="500" />
            </div>
          </div>
          <div class="form-line file-line">
            <div class="form-item">
              <div class="form-title">
                <!-- <span>*</span> -->
                添加文件
              </div>
              <el-upload
                ref="fileUpload"
                action="#"
                :limit="1"
                :disabled="fileDisable"
                list-type="text"
                :file-list="form.fileName"
                :on-exceed="handleExceedFile"
                :before-upload="beforeUploadFile"
                :http-request="handleUploadFile"
                :on-change="loadJsonFromFile_2"
                style="height: 102px;width:400px">
                <!-- 设定高度防止抖动 -->
                  <template #default>
                    <div class="file-upload" v-if="form.fileName.length === 0 && !fileDisable">
                      <img src="@/assets/images/incubator/upload-icon.png"/>
                      <p>上传文件</p>
                    </div>
                    <div class="file-upload" v-else-if="fileDisable">
                      <p>上传文件中</p>
                    </div>
                    <div class="flex-cc file-upload" v-else>
                      <p>{{form.fileName[0].name}}</p>
                      <div class="delete-button" @click.stop="deleteFile">
                        X
                      </div>
                    </div>
                  </template>
              </el-upload>
            </div>
            <div class="form-item" style="width: 402px;height:132px;">
              <div class="form-title">
                <!-- <span>*</span> -->
                添加场景化需求视频：
              </div>
              <el-upload
                ref="upload1"
                action="#"
                :disabled="mediaDisable"
                :limit="1"
                :accept="'video/*'"
                :file-list="form.videoName"
                :before-upload="beforeUpload1"
                :on-exceed="handleExceed1"
                :on-change="loadJsonFromFile_1"
                :http-request="handleUpload1">
                <template #default>
                  <div class="file-upload" v-if="form.video.length === 0 && !mediaDisable">
                    <img src="@/assets/images/incubator/upload-icon.png"/>
                    <p>上传视频</p>
                  </div>
                  <div class="file-upload" v-else-if="mediaDisable">
                    <p>上传视频中</p>
                  </div>
                  <div class="flex-cc file-upload" v-else>
                    <!-- <p>{{form.videoName[0].name}}</p> -->
                    <video controls @click.stop>
                      <source :src='form.video'>
                    </video>
                    <div class="delete-button" @click.stop="deleteMedia">
                      X
                    </div>
                  </div>
                </template>
            </el-upload>
            </div>
          </div>
          <div class="confirm-button" @click="postForm">
            确认提交
          </div>
        </div>
      </div>
    </div>
    <div class="section-3" id="section3">
      <div class="content">
        <p class="title">蓝珊瑚合作园区</p>
        <div class="flex-cc flex-b flex-w">
          <div class="part">
            <img src="@/assets/images/incubator/park-0.png" />
            <p class="info">
              张家港智汇港创新中心
            </p>
          </div>
          <div class="part">
            <img src="@/assets/images/incubator/park-1.png" />
            <p class="info">
              天镜湖创新中心
            </p>
          </div>
          <div class="part">
            <img src="@/assets/images/incubator/park-2.png" />
            <p class="info">
              智联部落创新中心
            </p>
          </div>
          <div class="part">
            <img src="@/assets/images/incubator/park-3.png" />
            <p class="info">
              中德创新中心基地
            </p>
          </div>
          <div class="part">
            <img src="@/assets/images/incubator/park-4.png" />
            <p class="info">
              沙溪生物医药创业孵化基地
            </p>
          </div>
          <div class="part">
            <img src="@/assets/images/incubator/park-5.png" />
            <p class="info">
              创立方科技企业孵化器
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { getPhoneCode,memberDetails,createOrg } from "@/api/user";
import { getprovince, getSelect, getcity, getarea} from "@/api/demand";
import { uploadTypeImage,uploadTypeVideo,uploadTypeFile, cosByUploadType,canUpload} from "@/api/upload";
import { bucketMap,fileStaticPath,videoStaticPath,requestVersion } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { getOrganizationTypes,getOrganizationTemplate } from "@/api/organization";
import { incubatorCapital,incubatorPark,incubatorProject } from "@/api/activity";

export default {
  data(){
    return{
      cur: 0,
      userInfo: {},
      hasType: false,
      fileDisable: false,
      mediaDisable: false,
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
      form:{
        name: '',
        personName: '',
        phone: '',
        code: '',
        address: '',
        addressArr: [],
        scale: '',
        introduce: '',
        describe: '',
        file: '',
        fileName: [],
        video: '',
        videoName: [],
        detail: '',
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
      scaleSelections: [],
      orgTypes:[],
      orgTemplate: [{},{},{}],
      orgForm: {},
    }
  },
  watch:{
    $route(to){
      if(to.path !== '/incubator') return
      this.clearForm()
      this.getQuery()
    },
    loadingForm(val){
      if(!val){
        this.setOrgForm()
      }
    },
  },
  computed:{
    textTitle(){
      switch (this.cur) {
        case 1:
          return {
            title: '请输入您的资本信息',
            form1: '资本名称：',
            form2: '联系方式：',
            form3: '投资标地：',
            form4: '投资规模：',
            form5: '描述需求：',
          }
        case 0:
          return {
            title: '请输入您的科技项目信息',
            form1: '项目名称：',
            form2: '联系方式：',
            form3: '项目简介：',
            form4: '项目描述：',
          }
        default:
          return {
            title: '请输入您的产业园信息',
            form1: '产业园名称：',
            form2: '联系方式：',
            form3: '产业园地址：',
            form4: '产业属性：',
            form5: '描述需求：',
          }
      }
    },
    loadingForm(){
      return (this.cur === 0 && !this.orgTemplate[0].templateName) || (this.cur === 1 && !this.orgTemplate[1].templateName) || (this.cur === 2 && !this.orgTemplate[2].templateName)
    },
  },
  mounted(){
    this.getQuery()
    this.getSelections()
    this.getOrganizationTemplates()
  },
  methods:{
    scrollToSmooth(eId){
      if(!eId) return
      const dom = document.getElementById(eId)
      if(!dom) return
      document.scrollingElement.scrollTo({
        top: dom.offsetTop - 60,
        behavior: "smooth",
      })
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getSelections(){
      getSelect({
        "limit": 100,
        "offset": 1,
        "type": "60"
      }).then(res=>{
        this.scaleSelections = res.data.result
      })
    },
    setOrgForm(){
      // 行业/投资领域/主导产业: 1078 地址: 北京市 110000 北京市 110100 东城区 110101 关注轮次: 1099 园区类型: 223265326956551 数量: 223266616705035 科创服务: 223266618802178
      switch (this.cur) {
        case 0:
          this.orgForm = this.orgTemplate[0].params.map((param,index)=>{
            let val = ''
            if(index === 1){
              val = '1078'
            }
            else if(index === 2){
              val = JSON.stringify({
                addressAreaId: '110101',
                addressAreaName: '东城区',
                addressCityId: '110100',
                addressCityName: '北京市',
                addressProvinceId: '110000',
                addressProvinceName: '北京市',
                addressDetail: '北京市-北京市-东城区',
              })
            }
            return{
              parameterId: param.id,
              val
            }
          })
          break;
        case 1:
          this.orgForm = this.orgTemplate[1].params.map((param,index)=>{
            let val = ''
            if(index === 1){
              val = '1078'
            }
            else if(index === 2){
              val = '1099'
            }
            else if(index === 4){
              val = JSON.stringify({
                addressAreaId: '110101',
                addressAreaName: '东城区',
                addressCityId: '110100',
                addressCityName: '北京市',
                addressProvinceId: '110000',
                addressProvinceName: '北京市',
                addressDetail: '北京市-北京市-东城区',
              })
            }
            return{
              parameterId: param.id,
              val
            }
          })
          break;
        case 2:
          this.orgForm = this.orgTemplate[2].params.map((param,index)=>{
            let val = ''
            if(index === 1){
              val = '223265326956551'
            }
            else if(index === 2){
              val = '1078'
            }
            else if([3,4,5].includes(index)){
              val = '223266616705035'
            }
            else if(index === 6){
              val = '223266618802178'
            }
            return{
              parameterId: param.id,
              val
            }
          })
          break;
        default:
          break;
      }
    },
    getOrganizationTemplates(){
      getOrganizationTypes(1).then(res=>{
        this.orgTypes = res.data.list.map((item)=>{
          item.newName = JSON.parse(item.extendConfigJson)
          return item
        })
        for (let index = 0; index < this.orgTypes.length; index++) {
          const element = this.orgTypes[index];
          if(['创业者','个人'].find(item=>element.newName?.iconName.includes(item))){
            // 项目
            getOrganizationTemplate(element.id).then(template=>{
              this.orgTemplate[0] = template.data
              this.orgTemplate[0].typeId = element.id
            })
          }
          else if(element.newName?.iconName.includes('资本')){
            // 资本
            getOrganizationTemplate(element.id).then(template=>{
              this.orgTemplate[1] = template.data
              this.orgTemplate[1].typeId = element.id

            })
          }
          else if(element.newName?.iconName.includes('园区')){
            // 园区
            getOrganizationTemplate(element.id).then(template=>{
              this.orgTemplate[2] = template.data
              this.orgTemplate[2].typeId = element.id
            })
          }
          else continue
        }
      })
    },
    getPhoneCode() {
      const TIME_COUNT = 60;
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(this.form.phone) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.form.phone })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
    postForm(){
      if(this.fileDisable || this.mediaDisable) {
        ElMessage.warning('正在上传，请稍等!')
        return
      }
      if(!this.userInfo.id){
        // 未登录则先登录
        if(!this.form.phone){
          ElMessage.warning('请输入手机号！')
          return
        }
        else if(!this.form.code){
          ElMessage.warning('请输入验证码！')
          return
        }
        const params = {
          machineCode: "",
          version: requestVersion,
          canal: 1,
          type: 0,
          code: this.form.code,
          phone: this.form.phone,
        }
        this.$store.dispatch("user/login", params).then(async () => {
          let userInfo = localStorage.getItem('userInfos')
          userInfo = JSON.parse(userInfo).memberInfo
          const { organizationType } = userInfo
          // 没有身份的跳去选择角色，助手除外 或者未选择行业前不打开窗口
          if (organizationType){
            this.$store.dispatch("user/updateVipInfo")
          }
          this.userInfo = userInfo
          let res = await canUpload(this.form.fileName[0].name)
          if(!res.data){  // 文件格式判断
            ElMessage.error('不支持上传此格式文件!')
            this.deleteFile() //提示后删除
            return
          }
          let result;
          result = await this.directUploadFile(this.form.fileName[0])
          // 路径+/年/月/日/时间戳/文件原名称(文件名称+类型)
          let path = result.Location.slice(result.Location.indexOf("/"))
          this.form.file = 'https://' + fileStaticPath + '/' + path.slice(0,path.indexOf('/',12) + 1) + this.form.fileName[0].name
          this.axiosForm()
        })
      }
      else {
        this.axiosForm()
      }
    },
    axiosForm(){
      let param = {}
      switch (this.cur) {
        case 0:
          if(!this.form.name){
            ElMessage.warning('请输入' + this.textTitle.form1)
            return
          }
          else if(!this.form.personName){
            ElMessage.warning('请输入联系人')
            return
          }
          else if(!this.form.introduce){
            ElMessage.warning('请输入' + this.textTitle.form3)
            return
          }
          else if(!this.form.describe){
            ElMessage.warning('请输入' + this.textTitle.form4)
            return
          }
          else if(!this.form.detail){
            ElMessage.warning('请输入项目需求')
            return
          }
          param = {
            "contactWay": this.userInfo.phone,
            "fileUrl": this.form.file,
            "projectData": this.form.describe,
            "projectInfo": this.form.introduce + '-----------项目需求：' + this.form.detail,
            "projectName": this.form.name,
            "videoUrl": this.form.video
          }
          incubatorProject(param).then(res=>{
            if(res.code === 'Sx200'){
              ElMessage.success('提交成功')
              this.clearForm()
            }
          })
          break;
        case 1:
          if(!this.form.name){
            ElMessage.warning('请输入' + this.textTitle.form1)
            return
          }
          else if(!this.form.scale){
            ElMessage.warning('请选择' + this.textTitle.form3)
            return
          }
          else if(!this.form.address){
            ElMessage.warning('请输入' + this.textTitle.form4)
            return
          }
          else if(!this.form.describe){
            ElMessage.warning('请输入' + this.textTitle.form5)
            return
          }
          else if(!this.form.detail){
            ElMessage.warning('请输入项目需求')
            return
          }
          param = {
            "capitalName": this.form.name,
            "contactWay": this.userInfo.phone,
            "demandRemark": this.form.describe + '-----------项目需求：' + this.form.detail,
            "fileUrl": this.form.file,
            "investScale": this.scaleSelections.find(item=>item.id + '' === this.form.scale + '')?.val,
            "investSign": this.form.address,
            "videoUrl": this.form.video,
          }
          incubatorCapital(param).then(res=>{
            if(res.code === 'Sx200'){
              ElMessage.success('提交成功')
              this.clearForm()
            }
          })
          break;
        case 2:
          if(!this.form.name){
            ElMessage.warning('请输入' + this.textTitle.form1)
            return
          }
          else if(this.form.addressArr.length < 3){
            ElMessage.warning('请选择' + this.textTitle.form3)
            return
          }
          else if(!this.form.scale){
            ElMessage.warning('请输入' + this.textTitle.form4)
            return
          }
          else if(!this.form.describe){
            ElMessage.warning('请输入' + this.textTitle.form5)
            return
          }
          else if(!this.form.detail){
            ElMessage.warning('请输入项目需求')
            return
          }
          param = {
            "contactWay": this.userInfo.phone,
            "demandRemark": this.form.describe + '-----------项目需求：' + this.form.detail,
            "fileUrl": this.form.file,
            "industrialParkAddress": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0] + '-' + this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1] + '-' + this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2],
            "industrialParkAttribute": this.form.scale,
            "industrialParkName": this.form.name,
            "videoUrl": this.form.video,
          }
          incubatorPark(param).then(res=>{
            if(res.code === 'Sx200'){
              ElMessage.success('提交成功')
              this.clearForm()
            }
          })
          break;
        default:
          break;
      }
      if(!this.userInfo.organizationId){
        this.createOrganization()
      }
    },
    createOrganization(){
      // 创建过程
      try {
        let param = {
          addressAreaId: '110101',
          addressAreaName: '东城区',
          addressCityId: '110100',
          addressCityName: '北京市',
          addressProvinceId: '110000',
          addressProvinceName: '北京市',
          // companyDepartmentId: '',
          // companyPositionId: '',
          // companyStaffName: '',
          industryList: [],
          label: [],
          license: ['keqidao'],
          memberId: this.userInfo.id,
          name: this.userInfo.name,
          orgIntroduction: null,
          organization: [],
          params: this.orgForm,
          productName: '',
          researchFund: '',
          scientificResearchCount: '',
          summary: '',
          typeId: this.orgTemplate[this.cur].typeId,
          version: this.orgTemplate[this.cur].version,
        }
        switch (this.cur) {
          case 0:
            param.params[0].val = this.form.personName
            break;
          case 1:
            param.params[0].val = this.form.name
            param.params[3].val = this.form.scale
            break;
          case 2:
            param.params[0].val = this.form.name
            param.addressAreaId = JSON.stringify(parseInt(this.form.addressArr[2]));
            param.addressCityId = JSON.stringify(parseInt(this.form.addressArr[1]));
            param.addressProvinceId = JSON.stringify(parseInt(this.form.addressArr[0]));
            param.addressAreaName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2]
            param.addressCityName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1]
            param.addressProvinceName = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0]
            param.params[7].val = JSON.stringify({
              'addressAreaId' : param.addressAreaId,
              'addressCityId' : param.addressCityId,
              'addressProvinceId' : param.addressProvinceId,
              'addressAreaName' : param.addressAreaName,
              'addressCityName' : param.addressCityName,
              'addressProvinceName' : param.addressProvinceName,
              'addressDetail': param.addressProvinceName + '-' + param.addressCityName + '-' + param.addressAreaName
            })
            break;
          default:
            break;
        }
        createOrg(param).then(res=>{
          if(res.code === 'Sx200'){
            console.log('create organization success');
          }
        })
      } catch (error) {
        console.log(error);
      }
    },
    getQuery(){
      if(localStorage.getItem('userInfos')){
        memberDetails(JSON.parse(localStorage.getItem('userInfos')).memberInfo.id).then(res=>{
          this.userInfo = res.data
        })
      }
      this.cur = this.$route.query.type || 0
      if(this.$route.query.type) this.hasType = true
      this.cur = Number(this.cur)
    },
    changeCur(type){
      if(this.cur === type) return
      this.clearForm()
      this.cur = type
      this.setOrgForm()
      // this.$router.push({
      //   path: '/incubator',
      //   query:{
      //     type
      //   }
      // })
    },
    clearForm(){
      this.form = {
        name: '',
        personName: '',
        phone: '',
        code: '',
        address: '',
        addressArr: [],
        scale: '',
        introduce: '',
        describe: '',
        file: '',
        fileName: [],
        video: '',
        videoName: [],
        detail: '',
      }
      if(this.timer){
        this.showCode = true;
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    handleExceedFile(){ // 文件超出个数限制时的钩子
      ElMessage.warning('最多只能上传1个文件～')
    },
    beforeUploadFile(file){
      // 上传文件
      if(file.size > 50 * 1024 * 1024){
        // 大小判断 50M
        ElMessage.warning('上传文件大小过大，不能超过50M!')
        return false
      }
    },
    async handleUploadFile(val){  // 进行上传
      if(val.file.size > 1073741824){ // 1024 * 1024 * 1024 文件大小限制
        ElMessage.warning('上传的文件最大仅支持1GB,请选择其他文件!')
        this.deleteFile() //提示后删除
        return
      }
      if(val.file.size === 0){ // 空文件判断
        ElMessage.warning('请勿上传空文件!')
        this.deleteFile() //提示后删除
        return
      }
      if(val.file.name.length > 70){    // 如果超过70个字符
        ElMessage.warning('文件名称过长,请修改!')
        this.deleteFile() //提示后删除
        return
      }
      if(!this.userInfo.id){
        // 未登录
        this.form.fileName = [val.file]
        return
      }
      let res = await canUpload(val.file.name)
      // console.log(res.data,'格式判断');
      if(!res.data){  // 文件格式判断
        ElMessage.error('不支持上传此格式文件!')
        this.deleteFile() //提示后删除
        return
      }
      let result;
      result = await this.directUploadFile(val.file)
      // 路径+/年/月/日/时间戳/文件原名称(文件名称+类型)
      let path = result.Location.slice(result.Location.indexOf("/"))
      this.form.file = 'https://' + fileStaticPath + '/' + path.slice(0,path.indexOf('/',12) + 1) + this.form.fileName[0].name
    },
    loadJsonFromFile_2(file,fileList){
      this.form.fileName = fileList
    },
    directUploadFile(file){ // 暂用video的桶
      let self = this
      let cos = cosByUploadType(uploadTypeFile)
      let bucket = bucketMap.file
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: randomFilename()+'/' + file.name,              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(progressData)
              self.$refs['fileUpload'].uploadFiles.map((item) => {
                if (item.raw.uid === file.uid) {
                  if(item.percentage !== 100){  // 已经上传完成不再操作
                    item.percentage = parseInt(progressData.percent * 100)
                    if(item.percentage === 100) {
                      ElMessage.success('上传文件成功!')
                      self.fileDisable = false
                    }
                    else  self.fileDisable = true
                  }
                }
              })
            }
          },
          function (err, data) {
            if (err) {
              console.log('fileError:',err,err.code);
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    deleteFile(){ // 删除文件
      this.form.file = ''
      this.form.fileName = []
    },
    deleteMedia(){
      this.form.video = ''
      this.form.videoName = []
    },
    beforeUpload1(file){
      // 上传封面
      if(file.size > 200 * 1024 * 1024){
        // 不超过20M
        ElMessage.warning('上传视频大小过大，不能超过200M!')
        return false
      }
    },
    handleExceed1 () { //文件超出个数限制时的钩子
      ElMessage.warning('最多只能上传1个视频')
    },
    async handleUpload1 (val) {
      let result = await this.directUpload(val.file, uploadTypeVideo)
      this.form.video = videoStaticPath + result.Location.slice(result.Location.indexOf("/"))
    },
    directUpload (file, uploadType) {
      var self = this
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo)
      }
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video
      let bucket = uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video
      return new Promise((resolve, reject) => {
      cos.putObject(
        {
          Bucket: bucket, /* 必须 */
          Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
          Key: randomFilename(),              /* 必须 */
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onProgress: function (progressData) {
            self.$refs['upload1'].uploadFiles.map((item) => {
              if (item.raw.uid === file.uid) {
                item.percentage = parseInt(progressData.percent * 100)
                if(item.percentage === 100) {
                  self.mediaDisable = false
                  ElMessage.success('上传文件成功!')
                }
                else  self.mediaDisable = true
              }
            })
          }
        },
        function (err, data) {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        }
      );
    })
    },
    loadJsonFromFile_1(file, fileList) {
      this.form.videoName = fileList
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  background: #f0f2f5;
  padding-bottom: 100px;
  margin-top: -36px;
  scroll-behavior: smooth;
  .top-img{
    width: 100%;
  }
  .form-back{
    width: 100%;
    margin-top: 80px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/09/20/1697807212180619');
    background-size: 100% 105%;
    background-repeat: no-repeat;
    padding-bottom: 65px;
    .line-options{
      width: 100%;
      justify-content: center;
      padding-bottom: 40px;
      .m-80{
        margin: 0 80px;
      }
      .option{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        cursor: pointer;
        padding: 7px 15px;
      }
      .active{
        border-radius: 8px;
        background: $--color-theme;
        color: #ffffff !important;
      }
    }
    .p-title{
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 50px;
      width: 100%;
      text-align: center;
      padding-top: 104px;
      padding-bottom: 50px;
    }
  }
  .form-container{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left-options{
      width: 161px;
      box-sizing: border-box;
      padding: 7px;
      background: #ffffff;
      border-radius: 8px;
      .option{
        width: 100%;
        height: 63px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #111111;
        line-height: 63px;
        text-align: center;
        cursor: pointer;
      }
      .active{
        border-radius: 8px;
        background: $--color-theme;
        color: #ffffff !important;
      }
    }
    .right-form{
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 63px 158px 50px 192px;
      .title{
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #111111;
        width: 100%;
        margin-bottom: 63px;
        text-align: center;
      }
      .form-title{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 16px;
        span{
          color: #ED0900 !important;
        }
        margin-bottom: 14px;
      }
      .file-line{
        height: 140px !important;
        overflow: hidden;
      }
      .form-line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .input{
          width: 400px;
          box-sizing: border-box;
          height: 40px;
          background: #F6F7FA;
          border-radius: 4px;
          border: 1px solid #D3D3D3;
          font-size: 14px;
          padding-left: 11px;
          padding-right: 11px;
        }
        :deep(.el-cascader){
          .el-input__inner{
            width: 400px;
            box-sizing: border-box;
            height: 40px;
            background: #F6F7FA;
            border-radius: 4px;
            border: 1px solid #D3D3D3;
            font-size: 14px;
            padding-left: 11px;
            padding-right: 11px;
            &::placeholder{
              color: #666666 !important;
            }
          }
        }
        .select-input{
          :deep(.el-input__inner){
            width: 400px;
            box-sizing: border-box;
            height: 40px;
            background: #F6F7FA;
            border-radius: 4px;
            border: 1px solid #D3D3D3;
            font-size: 14px;
            padding-left: 11px;
            padding-right: 11px;
            &::placeholder{
              color: #666666 !important;
            }
          }
        }
        .form-item{
          :deep(.el-upload){
            width: 400px;
            height: 102px;
          }
          :deep(.el-upload-list){
            display: none !important;
          }
          .file-upload{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px solid #D3D3D3;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: relative;
            video{
              width: 100%;
              height: 100%;
              background-color: #000;
              object-fit: contain;
              z-index: 10;
            }
            .delete-button{
              z-index: 20;
              position: absolute;
              right: 0px;
              top: 0px;
              width: 14px;
              cursor: pointer;
              height: 14px;
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              text-align: center;
              background-color: #000;

            }
            p{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #111111;
              line-height: 14px;
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 4px;
            }
          }
        }
        .form-contact{
          width: 400px;
          .input{
            width: 216px !important;
          }
          .phone-line{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .code-line{
              width: 170px;
              display: flex;
              align-items: center;
              .code{
                width: 90px;
                box-sizing: border-box;
                height: 40px;
                background: #F6F7FA;
                border-radius: 4px;
                border: 1px solid #D3D3D3;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                font-size: 14px;
                padding-left: 11px;
                padding-right: 11px;
              }
              .button{
                width: 75px;
                height: 40px;
                background: #2280FF;
                border-radius: 0px 4px 4px 0px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
              }
              .disable-code{
                background: #2282ff8a !important;
                cursor: not-allowed !important;
              }
            }
          }
        }
      }
      .form-text-area{
        margin-bottom: 30px;
        .textarea{
          box-sizing: border-box;
          padding: 14px 11px;
          width: 850px;
          height: 98px;
          background: #F6F7FA;
          border-radius: 4px;
          border: 1px solid #D3D3D3;
          font-size: 14px;
          resize: none;
        }
      }
      .confirm-button{
        width: 225px;
        height: 36px;
        background: $--color-theme;
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}
.custom-header{
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 2001;
  background: #ffffff;
  .header{
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    .logo{
      width: 163px;
      height: 40px;
      margin-right: 30px;
    }
    a{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 20px;
      &:visited{
        color: #111111;
      }
      &:hover{
        color: $--color-theme;
      }
    }
    p{
      margin: 0 33px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      cursor: pointer;
      line-height: 20px;
      &:hover{
        color: $--color-theme;
      }
    }
  }
}
.section-0{
  overflow: hidden;
  width: 100%;
  .img{
    width: 500px;
    height: 506px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
    position: relative;
    .title{
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 48px;
      margin-bottom: 47px;
    }
    .content{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 30px;
      letter-spacing: 1px;
    }
    .m-30{
      margin: 30px 0;
    }
    .bottom{
      height: 131px;
      background: #0862CC;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 131px;
      width: 200%;
      margin-top: 30px;
      padding-left: 127px;
    }
  }
}
.section-1{
  width: 100%;
  padding-top: 80px;
  .content{
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    .title{
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 50px;
      padding-bottom: 25px;
      width: 100%;
      text-align: center;
    }
    .img-line{
      margin-top: 21px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .img-section-big{
        width: 590px;
        position: relative;
        .img{
          width: 100%;
          height: 286px;
        }
        .info{
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 25px;
          position: absolute;
          top: 215px;
          width: 100%;
          text-align: center;
        }
        .bottom{
          width: 100%;
          box-sizing: border-box;
          background-color: #ffffff;
          margin-top: -2px;
          padding: 30px 0;
          p{
            width: 100%;
            text-align: center;
            box-sizing: border-box;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 20px;
            span{
              margin: 0 26px;
            }
          }
          .p-l{
            box-sizing: border-box;
            padding-left: 25px;
          }
        }
      }
      .img-section{
        width: 386px;
        position: relative;
        .img{
          width: 100%;
          height: 286px;
        }
        .info{
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 25px;
          position: absolute;
          top: 222px;
          width: 100%;
          text-align: center;
        }
        .bottom{
          width: 100%;
          box-sizing: border-box;
          background-color: #ffffff;
          margin-top: -2px;
          padding: 13px 0 17px;
          p{
            box-sizing: border-box;
            margin: 12.5px 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 20px;
            white-space: nowrap;
          }
          .left{
            width: 157px;
            text-align: right;
          }
          .left-small{
            width: 116px;
            text-align: right;
          }
          .right-small{
            width: 110px;
          }
          .middle{
            margin: 0 24px;
          }
          .right{
            width: 177px;
          }
        }
      }
    }
  }
}
.section-3{
  width: 100%;
  padding-top: 80px;
  .content{
    width: 1200px;
    margin: 0 auto;
    .title{
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 50px;
      padding-bottom: 40px;
      width: 100%;
      text-align: center;
    }
    .part{
      margin-top: 20px;
      width: 386px;
      position: relative;
      img{
        width: 100%;
        height: 305px;
      }
      .info{
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
        letter-spacing: 2px;
        position: absolute;
        top: 130px;
      }
    }
  }
}
</style>